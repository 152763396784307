import React from 'react';
import { Helmet } from "react-helmet-async";

import Hero from '../../components/hero';
import Aboutus from '../../components/Aboutus';
import Brands from '../../components/Brands';
import Casocial from "../../components/casocial";
import Team from '../../components/Team';
import FAQ from '../../components/FAQ';
import Tokenomics from '../../components/tokenomics';
import RoadMap from '../../components/RoadMap';

const Home = () => {
  return (
    
    <div className="tw-bg-black tw-bg-Hero tw-px-4 md:tw-px-16 lg:tw-px-32 tw-pb-20 lg:tw-pb-12">
       <Helmet>
        <title>EBM Avenue LLC - Eco-friendly Approach to DeFi and Crypto Adoption</title>
        <link rel="canonical" href="https://ebmavenue.io/" />
        <meta name="description" content="Welcome to EBM Avenue LLC. Discover our innovative solutions and commitment to sustainability in the cryptocurrency industry." />
        <meta name="keywords" content="EBM Avenue, crypto loans, discounts, cryptocurrency, sustainability, innovation, eco-friendly, Tokenomics, $EBM, transparency, fairness, value creation, UNGC, clean energy, climate change" /> 
        <meta property="og:title" content="EBM Avenue LLC - Eco-friendly Approach to DeFi and Crypto Adoption" /> 
        <meta property="og:description" content="Welcome to EBM Avenue LLC. Discover our innovative DeFi solutions and commitment to sustainability in the cryptocurrency industry." /> 
        <meta property="og:type" content="website" /> 
        <meta property="og:url" content="https://ebmavenue.io/" /> 
        <meta property="og:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png" /> 
        <meta property="og:site_name" content="EBM Avenue LLC" /> 
        <meta name="twitter:card" content="summary_large_image" /> 
        <meta name="twitter:site" content="@ebmavenuellc" /> 
        <meta name="twitter:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png" /> 
        <meta property="og:description" content="Discover our innovative solutions and commitment to sustainability in the cryptocurrency industry. Join us on our journey towards a greener future!" />
        <meta name="twitter:description" content="Discover our innovative solutions and commitment to sustainability in the cryptocurrency industry. Join us on our journey towards a greener future!" />
        <link rel="me" href="https://twitter.com/ebmavenuellc" /> 
        <link rel="me" href="https://linkedin.com/company/ebmavenue" /> 
        <link rel="me" href="https://facebook.com/EBmavenueLLC/" /> 
      </Helmet>
      

      <Hero   />
      
    <div >
    <Brands/>
    </div>
      <Aboutus />
      <Tokenomics/>
      
      <RoadMap/>
      <Team/> 
      <FAQ/>
      <Casocial/>
      
    </div>
  );
};

export default Home;