import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="tw-items-center tw-justify-between tw-justify tw-bottom-0 tw-w-full tw-fixed sm:tw-fixed md:tw-fixed">
  

    <div className="tw-flex-col tw-bg-button-gradient tw-w-full tw-py-0 tw-text-center"> 
        <p className="tw-m-0 tw-text-black">
          Copyright © 2024. All rights reserved by EBM Avenue LLC, Reg.No: Reg.No: 3788
          Financial Services Authority, St. Vincent & The Grenadines
        </p>
        <p className=" sm:tw-hidden tw-hidden lg:tw-flex  tw-m-0 tw-text-black tw-justify" style={{ fontSize:"14px" }}>
        The sole purpose of this website is to provide an explanation of EBM Avenue, its vision, and the $EBM token. It is not intended to serve as investment or financial advice. The crypto market is unpredictable & DYOR . Be mindful of tax implications, as profits might be liable for capital gains or other regional taxes.     </p>
      </div>
      
    </div>
    
    
  );
};

export default Footer;
