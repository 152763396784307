import React, { useState, useEffect } from "react";
//import { FaCar } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { IoSearch } from "react-icons/io5";

import { FaAngleDown } from "react-icons/fa6";
import { MdMenu } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";

const Header = () => {
  const [open1, setOpen1] = useState(false);
  const [holdersDropdownOpen, setHoldersDropdownOpen] = useState(false);
  const [scrollBackground, setScrollBackground] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);

  const handleNavigate = (path, sectionId) => {
    navigate(path);
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollBackground(true);
      } else {
        setScrollBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // <nav className="tw-top-0 tw-sticky  tw-bg-black sm:tw-bg-transparent sm:tw-relative tw-z-20 tw-w-full">

    <nav
      className={`tw-top-0 tw-w-full tw-z-30 ${
        scrollBackground ? "tw-bg-black  tw-fixed" : ""
      } sm:tw-fixed md:tw-fixed`}
    >
      <div className="header tw-px-4 md:tw-px-16 lg:tw-px-32  tw-items-center tw-bg-black tw-flex tw-font-medium tw-h-20  tw-mx-auto tw-justify-between">
        <div className="background-image tw-absolute tw-top-0 tw-left-0">
          <img
            src={"https://i.postimg.cc/d3bMzs0h/left-image.png"}
            alt="Aqua color lighting from the left"
            
          />
        </div>
        <div className="background-image xs:tw-hidden tw-absolute tw-top-0 tw-right-0">
          <img
            src={"https://i.postimg.cc/8kMV6vXW/right-image.png"}
            alt="Aqua color lighting from the right"

          />
        </div>
        <div className="">
          <img
            src={require("../../assets/images/EBM-Avenue-logo.png")}
            className="tw-object-contain tw-w-[180px]"
            alt="EBM Avenue Logo"
          />
        </div>

        <div className="lg:tw-flex  tw-flex tw-justify-right tw-gap-2">
          <button
            className="tw-px-4 tw-py-2 tw-rounded-full tw-bg-[#2CBEF6] tw-text-white"
            onClick={() => window.open("https://app.ebmavenue.io", "_blank")}
          >
            Launch App
          </button>
        </div>

        <ul className="header-menu lg:tw-flex tw-hidden tw-items-center tw-gap-8 tw-font-[Poppins]">
          <li>
            <Link
              to="/"
              onClick={() => {
                handleNavigate("/", "hero");
              }}
              className="tw-text-white"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to={"/"}
              onClick={() => handleNavigate("/", "aboutSection")}
              className="tw-text-white"
            >
              About us
            </Link>
          </li>
          <li>
            <Link to={"/vision"} className="tw-text-white">
              Vision
            </Link>
          </li>
          <li>
            <Link
              to={"/"}
              onClick={() => handleNavigate("/", "tokenSection")}
              className="tw-text-white"
            >
              Tokenomics
            </Link>
          </li>
          <li>
            <Link
              to={"/"}
              onClick={() => handleNavigate("/", "roadSection")}
              className="tw-text-white"
            >
              Road Map
            </Link>
          </li>
          <li>
            <Link
              to={"/"}
              onClick={() => handleNavigate("/", "faqs")}
              className="tw-text-white"
            >
              FAQs
            </Link>
          </li>

          <li className="tw-relative">
            <button
              className="tw-text-white  tw-flex tw-items-center"
              onClick={() => setHoldersDropdownOpen(!holdersDropdownOpen)}
            >
              Utilities
              <FaAngleDown color="white" className=" tw-ml-3" />
            </button>
            {holdersDropdownOpen && (
              <ul className="tw-absolute tw-p-0 tw-bg-white tw-mt-2 tw-py-2 tw-w-56 tw-rounded tw-shadow-lg tw-z-50">
                <li>
                  <Link
                    className="tw-text-black tw-flex tw-justify-between tw-items-center text-sm tw-py-2 tw-px-3"
                    to={"/Swap4less"}
                    onClick={() => setHoldersDropdownOpen(false)}
                  >
                    <span className="  tw-text-sm">Swap4Less</span>
                    
                  </Link>
                </li>
                <hr className=" tw-m-0" />
                <li>
                  <Link
                    className="tw-text-black tw-flex tw-justify-between tw-items-center text-sm tw-px-4 tw-py-2"
                    to={"/loans4free"}
                    onClick={() => setHoldersDropdownOpen(false)}
                  >
                    <span className="tw-text-sm">ZPLOANS </span>
                    
                  </Link>
                </li>
                <hr className=" tw-m-0" />
                <li>
                  <Link
                    className="tw-text-black  tw-flex tw-justify-between tw-items-center text-sm tw-px-4 tw-py-2"
                    to={"/stake4More"}
                    onClick={() => setHoldersDropdownOpen(false)}
                  >
                    <span className="tw-text-sm">Stake4More</span>
                    
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>

        {/*<div className="md:tw-block tw-hidden">
          <Button

            onClick={() => open()} 
            Icons={<FaRegUser color="white" />}
            label={!isConnected?("Connect Wallet"):(address.slice(0,4)+"...."+address.slice(39,42))}
          />
        </div>*/}

        <div
          className="tw-text-3xl lg:tw-hidden tw-pt-2 tw-z-50"
          onClick={() => setOpen1(!open1)}
        >
          {open1 ? <MdOutlineClose color="white" /> : <MdMenu color="white" />}
        </div>

        {/* Mobile nav */}
        <div
          className={` 
                lg:tw-hidden  tw-z-40  tw-bg-black tw-fixed tw-w-full tw-top-0 tw-bottom-0 tw-leading-10 tw-py-10 
                tw-duration-500 ${open1 ? "tw-left-0" : "tw-left-[-100%]"}`}
        >
          

          <div className="tw-pb-5 tw-px-8">
            <img
              src={require("../../assets/images/EBM-Avenue-logo.png")}
              className="tw-object-contain tw-w-[180px]"
              alt="EBM Logo"
            />
          </div>

          <ul className="tw-p-0 tw-relative tw-px-9 tw-pt-3 tw-border-t">
            <li>
              <Link
                to="/"
                onClick={() => {
                  setOpen1(false);
                  handleNavigate("/", "hero");
                }}
                className={isActive("/") ? "text-[#2CBEF6]" : "text-white"}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to={"/vision"}
                onClick={() => {
                  setOpen1(false);
                }}
                className="tw-text-white"
              >
                Vision
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                onClick={() => {
                  handleNavigate("/", "aboutSection");
                  setOpen1(false);
                }}
                className="tw-text-white"
              >
                About us
              </Link>
            </li>

            <li>
              <Link
                to={"/"}
                onClick={() => {
                  handleNavigate("/", "tokenSection");
                  setOpen1(false);
                }}
                className="tw-text-white"
              >
                Tokenomics
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                onClick={() => {
                  handleNavigate("/", "roadSection");
                  setOpen1(false);
                }}
                className="tw-text-white"
              >
                Road Map
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                onClick={() => {
                  handleNavigate("/", "faqs");
                  setOpen1(false);
                }}
                className="tw-text-white"
              >
                FAQs
              </Link>
            </li>
            <li className="tw-relative tw-text-white">
              <button
                className="  tw-text-white tw-flex tw-items-center"
                onClick={() => setHoldersDropdownOpen(!holdersDropdownOpen)}
              >
                Utilities
                <FaAngleDown color="white" className=" tw-ml-3" />
              </button>
              {holdersDropdownOpen && (
                <ul className="tw-absolute tw-p-0 tw-bg-white tw-mt-2 tw-py-2 tw-w-56 tw-rounded tw-shadow-lg tw-z-50">
                  <li>
                    <Link
                      className="tw-text-black tw-flex tw-justify-between tw-items-center text-sm tw-py-2 tw-px-3"
                      to={"/Swap4less"}
                      
                    >
                      <span className="  tw-text-sm">Swap4Less</span>
                    </Link>
                  </li>
                  <hr className=" tw-m-0" />
                  <li>
                    <Link
                      className="tw-text-black  tw-flex tw-justify-between tw-items-center text-sm tw-px-4 tw-py-2"
                      to={"/loans4free"}
                onClick={() => {
                  handleNavigate("/loans4free");
                  setOpen1(false);
                }}
                    >
                      <span className="  tw-text-sm">ZPLOANS</span>
                    </Link>
                  </li>
                  <hr className=" tw-m-0" />
                  <li>
                    <Link
                      className="tw-text-black  tw-flex tw-justify-between tw-items-center text-sm tw-px-4 tw-py-2"
                      to={"/stake4more"}
                onClick={() => {
                  handleNavigate("/stake4more");
                  setOpen1(false);
                }}
                    >
                      <span className="  tw-text-sm">Stake4More</span>
                     
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/*<li className=" tw-pt-5">
            <Button  
            onClick={() => open()} 

            className={''}
            Icons={<FaRegUser color="white" />}
            label={!isConnected?("Connect Wallet"):(address.slice(0,4)+"...."+address.slice(39,42))}
          />
            </li>*/}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
