import React from "react";
import { Route, Routes } from "react-router-dom";
// import Routes from "./Route.js";

import "./App.css";
import Home from "./screens/home";
import Header from "./components/header";
import Footer from "./components/footer";
import Swap4Less from "./screens/Swap4Less";
import Whitepaper from "./screens/Whitepaper";
import Stake4More from "./screens/Stake4More";
import Loans4Free from "./screens/Loans4Free";

import OurVision from "./screens/OurVision";

function App() {
 

  return (
    <div className="">
      <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/swap4less" element={<Swap4Less />} />
        <Route path="/loans4free" element={<Loans4Free />} />
        <Route path="/stake4More" element={<Stake4More />} />
        <Route path="/whitepaper" element={<Whitepaper />} />
        <Route path="/vision" element={<OurVision />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
