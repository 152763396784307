import React from "react";

//import { FaArrowRight } from "react-icons/fa6";
import ImageSlider from "../../components/ImageSlider";
import Casocial from "../../components/casocial";
import { Helmet } from "react-helmet-async";

const Loans4Free = () => {
  return (
    <div className="tw-bg-black tw-bg-Hero tw-px-4 md:tw-px-16 lg:tw-px-32  tw-pb-20">
      <Helmet>
    <title>Loans4Free: EBM Avenue's Interest-Free Loans via DeFi</title>
    <link rel="canonical" href="https://ebmavenue.io/Loans4Free" />
    <meta name="description" content="Explore EBM Avenue's Loans4Free program offering interest-free loans to EBM token holders. Leverage our DApp for secure and transparent transactions with fixed daily fees." />
    <meta name="keywords" content="EBM Avenue, interest-free loans, Loans4Free, EBM token, DeFi, smart contracts, crypto lending, fixed fees, financial empowerment" />
    <meta property="og:type" content="website" /> <meta property="og:url" content="https://ebmavenue.io/Loans4Free" /> 
    <meta property="og:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png"/>
    </Helmet>
   
    <div className=" sm:tw-pt-10 md:tw-pt-15 lg:tw-pt-20 ">
      <div className="row  g-5  tw-items-center tw-pt-8 ">
        <div className="col-md-6">
           <h1 className=" tw-text-center tw-text-white tw-text-center gradient-text tw-font-semibold tw-text-[45px]">
            Intrest Free Loans            </h1>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            EBM Avenue is pioneering a new era in crypto lending with its first Decentralized Application (DApp), offering interest-free loans to $EBM token holders. <br>
            </br><br>
            </br>This innovative platform leverages smart contracts to ensure secure and transparent transactions.
            </p>
            <h2 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Program Details            </h2>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Our DApp utilizes smart contracts to automate and secure the loan process, ensuring trust and efficiency.
            </p>
            <h2 className=" sm:tw-text-start tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Benefits           </h2>
            <p className=" sm:tw-text-start tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Community members can borrow the required amount by providing the necessary collateral and selecting their preferred loan duration.                     
            </p>         
        </div>
       
           <ImageSlider/> 
        
        </div>   
     
        <div className="tw-flex-col  tw-py-4">
              
            <h2 className="  tw-text-center tw-text-white  gradient-text tw-font-semibold tw-text-[40px]">
            Key Features          </h2>                  
            <li className=" tw-text-center tw-text-green  gradient-text tw-font-semibold ">
            Fixed Daily Platform Fee:: 
            </li>
            <p className=" tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">Instead of traditional interest, a fixed daily fee is applied, offering predictability and transparency.</p>
            <li className=" tw-text-center tw-text-green  gradient-text tw-font-semibold ">
            Flexible Terms:
            </li>
            <p className=" tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Borrowers have the freedom to choose their loan duration, tailored to their financial needs.</p>
            <li className=" tw-text-center tw-text-green  gradient-text tw-font-semibold">
            Empowerment: 
            </li>
            <p className=" tw-text-center tw-text-white  tw-leading-7 tw-pt-4 tw-text-xl">
            Access funds securely and efficiently, leveraging your $EBM tokens with public smart contracts that ensures transparency and security.</p>
        </div>
        </div>
        <Casocial/>
      </div>
    
      
      
    
  );
};

export default Loans4Free;
