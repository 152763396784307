import React from "react";

const Tokenomics = () => {
  return (
    <div
      id="tokenSection"
      className="tw-overflow-x-hidden tw-bg-Mesh tw-relative tw-bg-[#0a0a0a] tw-w-full tw-h-auto tw-mb-0 tw-pb-8"
    >
      <div className="tw-px-4 md:tw-px-16 lg:tw-px-32 ">
            <h2 className="tw-text-white tw-text-center gradient-text tw-font-semibold tw-text-4xl">
              TOKENOMICS
            </h2>
          </div>
          <div className="tw-flex tw-items-center  tw-justify-center tw-mt-8 tw-mb-10">
            <img
              src={require("../../assets/images/EBM-Tokenomics.png")}
              alt="EBM Avenue Tokenomics"
            />
          </div>
         
          <div className="tw-text-center tw-items-center tw-mt-0 ">
            <p className="tw-text-white tw-text-lg">
              <span className="gradient-text tw-text-2xl">
                * Unified Supply *
              </span>{" "}
              <span className="gradient-text tw-text-2xl">
                {" "}
                Fragmented Liquidity *{" "}
              </span>
              <span className="gradient-text tw-text-2xl">
                {" "}
                Enhanced Interoperability{" "}
              </span>
            </p>
            <p className="tw-text-white tw-text-lg tw-mt-0">
              EBM Avenue has adopted the LayerZero OFT standard to ensure
              unified and consistent token supply, reducing depeg risk,
              improving user experience, enhancing cross-chain interoperability,
              and unlocking fragmented liquidity <br></br>
              <br></br>
              $EBM tokenomics model shows how we value transparency, fairness,
              and value creation such that our tokens become{" "}
              <span className="gradient-text tw-text-2xl">premium </span>{" "}
              cryptocurrency beyond our ecosystem.
            </p>
          
    

        <div
          id="Stages"
          className="tw-flex tw-items-center tw-justify-center tw-mt-10"
        >
          <div className="container tw-max-w-screen-lg tw-mx-auto">
            <h2 className="tw-text-white gradient-text text-center tw-font-poppins tw-font-extrabold tw-text-[35px]">
              {" "}
              Presale - Your Choice Now or Later{" "}
            </h2>
            
            <p className="tw-text-white tw-text-center">
              {" "}
              EBM Presale is designed to have NO fear of missing out (FOMO),
              will last untill the supply exist in each stage
            </p>
            <p className="tw-text-white tw-text-center">
              {" "}
              No Private Sales, No Early sales

            Complete 50% of EBM token supply has been allocated to support this ICO / presale and community engagement{" "}
            </p>
            
            <p className="tw-text-white tw-text-center"> This is going to be an unique and exciting crypto presale / ICO opportunity which offers incentives to all participants aligned with our principle to empower communities.</p>
            {/*<p className="tw-text-white tw-text-center"> And an Extra 2-5 % of $EBM tokens can be availed for investing above 200 USD  </p>*/}
            <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-2  md:tw-grid-cols-2 lg:tw-grid-cols-5 gap-4 tw-items-center  tw-justify-center">
              <div className="tw-flex tw-flex-col tw-items-center  tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[150px]">
              <h2 className="gradient-text tw-text-4xl md:tw-text-3xl sm:tw-text-2xl xs:tw-text-xl">Stage 1</h2>
              <p className="tw-text-lg md:tw-text-base sm:tw-text-sm xs:tw-text-xs tw-text-white"> 20 % of Presale</p>

                <h3 className="tw-text-white tw-text-xl">$EBM = $0.0012</h3>

                <h3 className="gradient-text tw-text-xl"> Next + 16.67 % </h3>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center  tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[150px]">
              <h2 className="gradient-text tw-text-4xl md:tw-text-3xl sm:tw-text-2xl xs:tw-text-xl">Stage 2</h2>
              <p className="tw-text-lg md:tw-text-base sm:tw-text-sm xs:tw-text-xs tw-text-white"> 30 % of Presale</p>
              <h3 className="tw-text-white tw-text-xl">$EBM = $0.0014</h3>

                <h3 className="gradient-text tw-text-xl">Next + 14.29 %</h3>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[150px]">
              <h2 className="gradient-text tw-text-4xl md:tw-text-3xl sm:tw-text-2xl xs:tw-text-xl">Stage 3</h2>
              <p className="tw-text-lg md:tw-text-base sm:tw-text-sm xs:tw-text-xs tw-text-white"> 35 % of Presale</p>

              <h3 className="tw-text-white tw-text-xl">$EBM = $0.0016</h3>

                <h3 className="gradient-text tw-text-xl">Next + 12.50 %</h3>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[150px]">
              <h2 className="gradient-text tw-text-4xl md:tw-text-3xl sm:tw-text-2xl xs:tw-text-xl">Stage 4</h2>
              <p className="tw-text-lg md:tw-text-base sm:tw-text-sm xs:tw-text-xs tw-text-white"> 15 % of Presale</p>

                <h3 className="tw-text-white tw-text-xl">$EBM = $0.0018</h3>

                <h3 className="gradient-text tw-text-xl">Next + 22.22 %</h3>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-min-h-[150px]">
              
              <h2 className="gradient-text tw-text-4xl md:tw-text-3xl sm:tw-text-2xl xs:tw-text-xl">Launch Price</h2>

                <h3 className="tw-text-white tw-text-xl">$EBM = $0.0022</h3>

                <h3 className="gradient-text tw-text-xl"> 83.33 %</h3>
              </div>
            </div>
            <div className="container tw-max-w-screen-lg tw-mx-auto tw-mb-0">
              <br></br>
              <p className="tw-text-white tw-text-center">
                {" "}
                <span className="gradient-text">Get Bonus of 1-13 % </span>of
                $EBM tokens when spending 100 USD and above{" "}
              </p>
              
              <p className="tw-text-white tw-text-center tw-text-xl">
                {" "}
                <span className="gradient-text">Transparency</span> Visit <a href="https://docs.ebmavenue.io/">EBM Documentation</a> to learn more about $EBM Presale
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
