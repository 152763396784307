//src/components/ImageSlider.jsx
import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
const images = [
    require("../assets/images/1.png"),
    require("../assets/images/2.png"),
    require("../assets/images/3.png"),
  require("../assets/images/4.png"),
  require("../assets/images/5.png"),
  require("../assets/images/6.png")
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000, // Slide every 3 seconds
  rtl: true, 
};

function ImageSlider() {
  return (
    <div className=" col-lg-6 col-md-12 tw-items-center tw-justify-center">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <Link to="https://app.ebmavenue.io/" target="_blank" className="tw-cursor-pointer">
            <img src={image} alt="Demo screens" style={{ height: '500px' }} class name="tw-cursor-pointer" />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImageSlider;