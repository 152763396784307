import React from "react";
import Button from "../Button";

const RoadMap = () => {
  return (
    <div id="roadSection" className="tw-py-4 tw-w-auto ">
      <div className=" tw-text-center">
          <h2 className="tw-text-center gradient-text tw-text-3xl">$EBM ROADMAP</h2>
          <p className="tw-text-white tw-leading-8">
          Our roadmap reflects our commitment to innovation, benefit communities, promote crypto adoption, and sustainability. Further guiding us towards our vision of promoting crypto mining reputation and supporting various welfare initiatives.
          </p> </div>
      
        
          <div id="roadSection" className="tw-py-4 tw-w-auto tw-relative ">
          <div className="tw-absolute tw-top-0 tw-left-1/2 tw-h-full tw-transform tw-translate-x-1/2 tw-z-0">
        <div className="tw-bg-button-gradient tw-w-1 tw-h-full"></div>
      </div>

          <div className=" tw-flex tw-flex-wrap tw-justify-around tw-relative tw-z-1">
            <div className="g-5 row tw-items-center">
              
              <RoadMapPhase
                phase="Phase 1"
                title="Foundation (2024 Q3 – Q4)"
                items={[
                  "Establishing the core team and advisory board.",
                  "Community benefiting business model and tokenomics.",
                  "Navigating UNGC, UN's SDGs and related global initiatives",
                  "Smart contract Audits",
                  "Private/Seed Sale (Cancelled)",
                  "Building early partnerships with DEX and CEX platforms to ensure liquidity and visibility",
                  
                  

                ]}
              />
              <RoadMapPhase
                phase="Phase 2"
                title="Business & Eco System (2025 Q1 – Q2)"
                items={[
                  "$EBM presale for community support and participation",
                  "CMC, and CG listings",
                  "Global Marketing to reach eco-conscious investors, and Potential CEX listings",
                  "dApp Launch - Interest Free Loans",
                  "Build, Develop EBM Farms & Swap4Less",
                  "Enabling Layer Zero for cross chain interoperability",
                  
                ]}
              />
              <div className="col-md-6 sm:tw-block tw-hidden tw-mt-0 tw-mb-0"></div>
              <div className="col-md-6 sm:tw-block tw-hidden tw-mt-0 tw-mb-0"></div>
              <RoadMapPhase
                phase="Phase 3"
                title="Infrastructure Development (2025 Q3 – Q4)"
                items={[
                  "Securing Permits & Building renewable energy plant",
                  "Securing renewable energy certifications to build trust",
                  "Build first Mining facility",
                  "dApp Live : Swap4Less",
                  "Activating engaging in the UNGC through operational involvement in the UN SDSN.",
                ]}
              />
              <RoadMapPhase
                phase="Phase 4"
                title="Integrations & Expansions  (2026 Q1 – Q2)"
                items={[
                  
                  "Partnering with other key players in the blockchain and Gaming industry.",
                  "Scaling up mining operations, adding hash power",
                  "dApp Live : Stake4More",
                  "Include a diverse range of cryptocurrencies (like BlockDAG) to mining portfolio",
                 
                ]}
              />
              <div className="col-md-6 sm:tw-block tw-hidden"></div>
              <div className="col-md-6 sm:tw-block tw-hidden"></div>
              <RoadMapPhase
                phase="Phase 5"
                title="Expansion and Growth (2026 Q3 – Q4)"
                items={[
                  "Securing sustainability partnerships for energy-efficient mining expansion",
                  "Geographically diversifying mining operations to multiple countries or regions",
                  "Establishing compliance frameworks with local governments in each region of expansion",
                  "Potential EBM dApp to full scale DEX with Governance Tokens",
                ]}
              />
              <RoadMapPhase
                phase="Phase 6"
                title="Sustainability and Innovation (Ongoing)"
                items={[
                  
                  "Exploring new technologies, trends, and opportunities in the crypto space for long-term value creation.",
                  
                  "Engaging with the global welfare and community driven projects for sustainability.",
                  
                ]}
              />
              
            </div>
          </div>
          
        </div>
        </div>
     
   
    
  );
};

const RoadMapPhase = ({ phase, title, items }) => (
  <div className="col-lg-6 col-md-12">
    <div className="row">
      <div className="col-md-11 tw-mx-auto">
        <div className="tw-bg-[#171717] tw-p-7 tw-rounded-md">
          <Button label={phase} className="tw-py-1" />
          <h3 className="tw-text-white tw-text-xl tw-pt-4 tw-pt-0">{title}</h3>
          <ul className="tw-p-0 tw-leading-8 tw-pt-0 tw-mb-0">
            {items.map((item, index) => (
              <li key={index} className="tw-flex tw-gap-3 tw-items-center tw-m-0">
                <div>
                  <img
                    src={require("../../assets/images/arrow_p.png")}
                    alt="Arrow"
                  />
                </div>
                <p className="m-0 tw-text-white">{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default RoadMap;
