import React from "react";

import { Link } from "react-router-dom";

const Casocial = ()=>{

    return(

        <div className="tw-flex tw-justify tw-items-center tw-justify-between tw-h-8   ">
            <div className="tw-flex ">
                <div className="tw-flex tw-items-center tw-mr-2">
                <img
                    src={"https://i.postimg.cc/y6GpjFMG/coin.png"}
                    className="tw-object-contain tw-w-[50px]"
                    alt="EBM Token Logo"
                />
                </div>
                <div className="sm:tw-hidden tw-hidden lg:tw-flex tw-flex-col tw-ml-1 tw-items-center">
                <p className="tw-text-white tw-text-sm md:tw-text-base">
                    EVM & BSC:
                    <a href="https://www.bscscan.com/token/0x43ed084aadc897ff94f41d0dac02a9571dc8339f" 
                    target= "_blank">_0x43ed084aadc897ff94f41d0dac02a9571dc8339f</a>
                    <br></br>
                    Solana: Coming Soon.
                </p>
                
                </div>
                <div className="lg:tw-hidden tw-flex tw-flex-col  tw-ml-1 tw-items-center">
                <p className="tw-text-white tw-text-sm md:tw-text-base">
                    CA:
                    <a href="https://www.bscscan.com/token/0x43ed084aadc897ff94f41d0dac02a9571dc8339f" 
                    target= "_blank">0x43e.....c8339f</a>
                    <br></br> Solana: Soon..
                </p>
                
                </div>

            </div>
            <div className="tw-flex tw-items-center">
                <a
                href="https://t.me/ebmavenuellc"
                target="_blank"
                rel="noopener noreferrer"
                className="tw-mx-2"
                >
                <i className="fab fa-telegram-plane tw-text-white tw-text-2xl"></i>
                </a>
                <a
                href="https://twitter.com/ebmavenuellc"
                target="_blank"
                rel="noopener noreferrer"
                className="tw-mx-2"
                >
                <i className="fab fa-twitter tw-text-white tw-text-2xl"></i>
                </a>
                <a
                href="https://facebook.com/ebmavenuellc"
                target="_blank"
                rel="noopener noreferrer"
                className="tw-mx-2"
                >
                <i className="fab fa-facebook-f tw-text-white tw-text-2xl"></i>
                </a>
                
                <a
                href="https://linkedin.com/company/ebmavenuellc"
                target="_blank"
                rel="noopener noreferrer"
                className="tw-mx-2"
                >
                <i className="fab fa-linkedin-in tw-text-white tw-text-2xl"></i>
                </a>
            </div>
            <div className=" tw-flex tw-items-center ">
                <ul className="tw-flex tw-gap-2 tw-font-[Poppins] tw-text-white">
                <li>
                    <Link
                    to="https://docs.ebmavenue.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tw-text-white tw-text-sm md:tw-text-base"
                    >
                    Docs
                    </Link>
                </li>
                
                <li>
                    <Link
                    to="https://ebm.farms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tw-text-white tw-text-sm md:tw-text-base"
                    >
                    Web3
                    </Link>
                </li>
                {/* Add more links as needed */}
                </ul>
            </div>
            </div>
        
    );

};

export default Casocial;