import React from "react";
import token from "../../assets/images/token.png";


const TokenUtility = () => {
  return (
    
      <div className="tw-relative tw-bg-Hero tw-pt-12 sm:tw-pb-8 ">
        <h2 className="tw-text-white tw-text-center gradient-text tw-font-semibold md:tw-text-[45px] tw-text-[35px]">
          TOKEN UTILITIES
        </h2>

        <div className="row  g-5 tw-py-2  ">
          <div className="col-md-4  tw-order-2 sm:tw-order-1 p-0">
            <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
              <div className="tw-flex tw-top-0 tw-left-12 tw-w-full tw-h-full">
                <img
                  src={token}
                  className="tw-mx-auto tw-w-64 sm:tw-w-auto tw-rounded-md"
                  alt="Token"
                />
              </div>

              
            </div>
          </div>

          <div className="col-md-8 sm:tw-order-2 tw-order-1 p-0">
            <div className=" tw-px-10">
              <div className=" sm:tw-text-start tw-text-center tw-px-5  tw-py-8 tw-rounded-lg sm:tw-flex tw-block tw-items-center tw-gap-4">
                <h3 className="tw-text-white sm:tw-text-[40px] tw-text-[30px] gradient-text">Payments</h3>
                <p className=" tw-m-0  tw-h-20 tw-w-0.5  sm:tw-block tw-hidden tw-bg-[#2596ef]"></p>
                <p className="tw-text-white">
                $EBM can be used to access EBM Farms Ecosystem for Swaps, Crypto Loans etc.
                $EBM can be use as payment method at our partners'  web3 integrated games and market places.
                </p>
              </div>

              <div className="tw-ml-0 md:tw-ml-24 tw-mt-2">
                <div className="tw-mb-8 sm:tw-text-start tw-text-center">
                  <h3 className="tw-text-white sm:tw-text-[40px] tw-text-[30px] gradient-text">Discounts</h3>
                  <hr className=" sm:tw-block tw-hidden tw-m-0 tw-border-2 tw-w-44  tw-border-[#2596ef] tw-opacity-100" />
                  <p className="tw-text-white tw-pt-5">
                    $EBM Token holders can swap USDT for currencies mined by EBM Farms at 1-10% discounted rate through Swap4Less
                    based on their $EBM holding levels.<a href="Swap4Less" className="text-gradient tw-underline"> Learn More</a>
                  </p>
                </div>

                <div className=" sm:tw-text-start tw-text-center">
                  <h3 className="tw-text-white sm:tw-text-[40px] tw-text-[30px] gradient-text ">
                  Interest Free Loans

                  </h3>
                  <hr className=" sm:tw-block tw-hidden tw-m-0 tw-border-2 tw-w-44  tw-border-[#2596ef] tw-opacity-100" />
                  <p className="tw-text-white tw-pt-5">
                  $EBM offers Interest Free Loans with a simple daily administrative fee irrespective of loan amount or duration. Everything will be transparent on Blockchain & Smart contracts.
                  <a href="https://docs.ebmavenue.io/" className="text-gradient tw-underline"> Learn More</a>



                  </p>
                </div>
                <div className=" sm:tw-text-start tw-text-center ">
                  <h3 className="tw-text-white sm:tw-text-[40px] tw-text-[30px] gradient-text ">
                  Community Benefit

                  </h3>
                  <hr className=" sm:tw-block tw-hidden tw-m-0 tw-border-2 tw-w-44  tw-border-[#2596ef] tw-opacity-100" />
                  <p className="tw-text-white tw-pt-5">
                  Through Stake4More, community can enjoy higher discounts and rewards for fulfilling crypto journey.
                  <a href="Stake4More" className="text-gradient tw-underline"> Learn More</a>



                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default TokenUtility;
