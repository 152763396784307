import React from 'react';

import { Helmet } from "react-helmet-async";
import Button from '../Button';
import { FaArrowRight } from "react-icons/fa6";

import VideoPlayer from "../videoPlayer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Hero = () => {
  // const [showCounter, setShowCounter] = useState(false);

  const openPdfInNewTab = () => {
    const pdfUrl = "https://ebmavenue.io/EBM-Whitepaper.pdf";
    window.open(pdfUrl, "_blank");
  };

  return (
    <div id="hero" className="tw-bg-Hero tw-bg-cover tw-bg-black tw-bg-center ">
      <Helmet>
        <title>EBM Avenue LLC - Eco-friendly Approach to DeFi and Crypto Adoption</title>
        <link rel="canonical" href="https://ebmavenue.io/" />
        <meta name="description" content="Welcome to EBM Avenue LLC. Discover our innovative solutions and commitment to sustainability in the cryptocurrency industry." />
        <meta name="keywords" content="EBM Avenue, crypto loans, discounts, cryptocurrency, sustainability, innovation, eco-friendly, Tokenomics, $EBM, transparency, fairness, value creation, UNGC, clean energy, climate change" /> 
        <meta property="og:title" content="EBM Avenue LLC - Eco-friendly Approach to DeFi and Crypto Adoption" /> 
        <meta property="og:description" content="Welcome to EBM Avenue LLC. Discover our innovative DeFi solutions and commitment to sustainability in the cryptocurrency industry." /> 
        <meta property="og:type" content="website" /> 
        <meta property="og:url" content="https://ebmavenue.io/" /> 
        <meta property="og:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png" /> 
        <meta property="og:site_name" content="EBM Avenue LLC" /> 
        <meta name="twitter:card" content="summary_large_image" /> 
        <meta name="twitter:site" content="@ebmavenuellc" /> 
        <meta name="twitter:image" content="https://ebmavenue.io/EBM4DeFi-1200-560.png" /> 
        <meta property="og:description" content="Discover our innovative solutions and commitment to sustainability in the cryptocurrency industry. Join us on our journey towards a greener future!" />
        <meta name="twitter:description" content="Discover our innovative solutions and commitment to sustainability in the cryptocurrency industry. Join us on our journey towards a greener future!" />
        <link rel="me" href="https://twitter.com/ebmavenuellc" /> 
        <link rel="me" href="https://linkedin.com/company/ebmavenue" /> 
        <link rel="me" href="https://facebook.com/EBmavenueLLC/" /> 
      </Helmet>
      
      
      <div className="tw-pt-6 tw-pb-14 lg:tw-pt-32">
        <div className="row g-8 tw-items-center">
          <div className="col-lg-6 col-md-14">
            <h1 className="tw-text-white md:tw-text-6xl tw-text-xl">
              Welcome to {" "}
              <span className="gradient-text"> Eco-Friendly Blockchain Mining </span> Avenue !!!
            </h1>
            <p className="tw-text-white tw-leading-7 tw-pt-0 tw-mb-0 tw-text-xl">
            An avenue dedicated to promoting DeFi and benefiting communities. <br /><br />
            As a proud supporter and ally of the United Nations Global Compact (UNGC) principles, we advocate for responsible business practices aligned with global sustainability goals. <br /><br />
            While we do not provide crypto mining products or services, EBM harnesses the power of blockchain, DeFi, and tokenization to drive diverse business verticals, including Loans, Crypto Mining, Games, Education, and more. Our innovative approaches aim to generate revenue and deliver benefits to communities. <br /><br />
            Join us on this fulfilling journey, where every step is a leap towards a better world.
            </p>
            <div className="tw-flex tw-gap-7 sm:tw-justify-start tw-justify-center tw-mb-1">
              <Button
                onClick={openPdfInNewTab}
                label={"White Paper"}
                className={"tw-mt-7"}
                rIcons={<FaArrowRight color="white" />}
              />
              <Button
                onClick={() => window.open("https://app.ebmavenue.io", "_blank")}
                label={"Launch App"}
                className={"tw-mt-7"}
                rIcons={<FaArrowRight color="white" />}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 tw-flex tw-justify-end tw-mb-0">
            {/*}
            {showCounter ? (
              <div className="tw-bg-[#282E32] tw-border-[#269FF0] tw-border-2 tw-rounded-2xl tw-h-auto tw-bg-cover bg-hero-cut" style={{ width: '510px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <iframe className="tw-rounded-2xl" width="500" height="580" frameBorder="0" scrolling="no" src="https://gempad.app/embed?address=0xC2E1c4Aa64e4eef79D3beC03A5f8F9948b4c58bD&bgColor=%2312181F&fgColor=%23161D27&hasAnimation=true&hasBanner=true&hasSocialLinks=true&network=BNB_Smart_Chain&padding=20&refer=0xdd1ECbb8aAC2Fb3109f20c85012B49248cdfe8ca&responsive=true&saleType=presale&theme=dark&txtColor=%23FFFFFF"></iframe>
              </div>
            ) : (
              <VideoPlayer src={require("../../assets/images/ebm.mp4")} thumbnail={require("../../assets/images/EBMExplained2.png")} />
            )}*/}
            <VideoPlayer src={require("../../assets/images/ebm.mp4")} thumbnail={require("../../assets/images/EBMExplained2.png")} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Hero;
